
import { computed, defineComponent, PropType, ref } from 'vue';
import { useVModel } from '@vueuse/core';
import dayjs from 'dayjs';

export default defineComponent({
  props: {
    modelValue: {
      type: Array as PropType<Date[]>,
      required: true,
    },
    name: {
      type: String,
    },
    label: {
      type: String,
      default: '日期區間',
    },
    placeholder: {
      type: String,
      default: '請點擊選擇日期區間',
    },
    rules: {
      type: Array,
    },
    showFormat: {
      type: String,
      default: 'YYYY/MM/DD',
    },
    size: {
      type: String,
      default: 'large',
    },
  },
  setup(props, { emit }) {
    const showPicker = ref(false);
    const date = useVModel(props, 'modelValue', emit);
    const showLabel = computed(() => {
      const [start, end] = date.value;
      return `${dayjs(+start).format(props.showFormat)}-${dayjs(+end).format(props.showFormat)}`;
    });

    const minDate = dayjs()
      .subtract(1, 'years')
      .toDate();
    const maxDate = dayjs().toDate();

    function onConfirm([start, end]: Date[]) {
      // console.log([start, end]);
      date.value = [
        start,
        dayjs(end)
          .endOf('day')
          .toDate(),
      ];
      showPicker.value = false;
    }

    // console.log(date.value);

    return {
      showPicker,
      showLabel,
      date,
      minDate,
      maxDate,

      onConfirm,
    };
  },
});

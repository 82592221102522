import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_calendar = _resolveComponent("van-calendar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_field, {
      modelValue: _ctx.showLabel,
      name: _ctx.name,
      label: _ctx.label,
      placeholder: _ctx.placeholder,
      rules: _ctx.rules,
      clickable: "",
      readonly: "",
      "right-icon": "arrow-down",
      size: _ctx.size,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPicker = true))
    }, null, 8, ["modelValue", "name", "label", "placeholder", "rules", "size"]),
    _createVNode(_component_van_calendar, {
      show: _ctx.showPicker,
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showPicker) = $event)),
      type: "range",
      "default-date": _ctx.date,
      title: _ctx.label,
      "min-date": _ctx.minDate,
      "max-date": _ctx.maxDate,
      "allow-same-day": "",
      onConfirm: _ctx.onConfirm
    }, null, 8, ["show", "default-date", "title", "min-date", "max-date", "onConfirm"])
  ], 64))
}